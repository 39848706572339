import React from 'react';
import {Freshchat} from "./Freshchat";

export const Footer = () => {
    return (
        <div>
            <section className="copyright_bar">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-info">
                            <img className="footer_logo" src="https://cdn.snapplify.com/1.0.11/img/logo_white.png" border="0" alt=""/>
                            <p>&copy; 2013 - 2022 Snapplify. All Rights Reserved.</p>
                            <ul>
                                <li><a href="https://shop.snapplify.com/page/terms-and-conditions" target="_blank" rel="noopener noreferrer">Terms & Conditions</a>&nbsp;&nbsp;|&nbsp;&nbsp;</li>
                                <li><a href="https://shop.snapplify.com/page/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>&nbsp;&nbsp;|&nbsp;&nbsp;</li>
                                <li><a href="https://shop.snapplify.com/page/returnrefund-policy" target="_blank" rel="noopener noreferrer">Return/Refund Policy</a></li>
                            </ul>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 social_icons">
                            <a className="social_icon" href="https://www.linkedin.com/company/snapplify" target="_blank" rel="noopener noreferrer"><img src="https://cdn.snapplify.com/1.0.4/img/linkedin_icon.svg" border="0" alt=""/></a>
                            <a className="social_icon" href="https://www.instagram.com/snapplify" target="_blank" rel="noopener noreferrer"><img src="https://cdn.snapplify.com/1.0.4/img/instagram_icon.svg" border="0" alt=""/></a>
                            <a className="social_icon" href="https://twitter.com/Snapplify" target="_blank" rel="noopener noreferrer"><img src="https://cdn.snapplify.com/1.0.4/img/twitter_icon.svg" border="0" alt=""/></a>
                            <a className="social_icon" href="https://www.facebook.com/snapplify" target="_blank" rel="noopener noreferrer"><img src="https://cdn.snapplify.com/1.0.4/img/facebook_icon.svg" border="0" alt=""/></a>
                        </div>
                    </div>
                </div>
            </section>
            <Freshchat/>
        </div>
    );
};
