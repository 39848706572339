import React, {useEffect, useState} from 'react';
import './App.css';
import {Header} from './components/Header';
import {Footer} from './components/Footer';
import {ReaderActive, ReaderStatic} from "./components/Applications";
import {Snappbot} from "./components/Robot";
import {SnapplifyLogo} from "./components/SnapplifyLogo";
import {GDELogo} from "./components/GDELogo";

export const App = () => {
    // State to track whether the GDE chooser should be shown
    const [showGDEChooser, setShowGDEChooser] = useState(false);
    const [isGDE, setIsGDE] = useState(null); // Track if user is GDE or not

    // Check query parameters on mount
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('chooser') === 'true') {
            setShowGDEChooser(true);
        }
    }, []);

    // Function to handle GDE confirmation
    const handleGDEChoice = (choice) => {
        if (choice === 'yes') {
            window.location.href = 'https://getgdecloud.com'; // Redirect to GDE Cloud
        } else {
            setIsGDE(false); // Display the current app
        }
    };

    return (
        <div className="App">
            <Header/>

            <section className="main-container" style={{flexGrow: 1}}>
                <div className="container">
                    <div className="main-content-box">
                        <div className="row">
                            {showGDEChooser && isGDE === null ? (
                                // Display GDE Chooser if `chooser` param is true and user hasn't responded yet
                                <div className="gde-chooser">
                                    <h1>It’s time to upgrade.</h1><br/>
                                    <p>Great news, there is a new version of your Reader application available.</p>
                                    <p>It appears that you are from a Gauteng Department of Education School and are using the GDE Reader.</p>
                                    <p>If you are using the GDE Reader please click the GDE button, otherwise please click the Snapplify button.</p>
                                    <div className="logo-container">
                                        <GDELogo onClick={() => handleGDEChoice('yes')}/>
                                        <SnapplifyLogo onClick={() => handleGDEChoice('no')}/>
                                    </div>
                                </div>
                            ) : (
                                // Display the normal app content if not a GDE
                                <>
                                    <Snappbot/>
                                    <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8">
                                        <ReaderActive/>
                                        <ReaderStatic/>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </section>

            <Footer/>
        </div>
    );
}