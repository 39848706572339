import React from 'react';
import Logo from '../img/logo-gde.png'

export const GDELogo = ({onClick}) => {
    return (
        <img
            src={Logo}
            alt="GDE Logo"
            onClick={onClick}
            className="app-button"
        />
    );
};