import React, {useEffect, useState} from "react";
import LogoWindows from "../img/logo-windows.png";
import LogoMicrosoft from "../img/logo-microsoft.png";
import LogoMac from "../img/logo-mac.png";
import LogoIOS from "../img/logo-ios.png";
import LogoHuawei from "../img/logo-huawei.png";
import LogoAndroid from "../img/logo-android.png";
import LogoMacM1 from "../img/logo-mac-m1.png";

const isAppleSilicon = () => {
    try {
        const canvas = document.createElement('canvas');
        const gl = canvas.getContext('webgl');

        if (!gl) return false;

        // Best guess if the device is an Apple Silicon
        // <https://stackoverflow.com/a/65412357>
        return gl.getSupportedExtensions().indexOf('WEBGL_compressed_texture_etc') !== -1;
    } catch (error) {
        return false;
    }
}

const createReader = (platform, isPrimary, logo, src) => {
    return {
        platform,
        isPrimary,
        logo,
        src
    }
}

const getReaders = () => {
    const userAgent = navigator.userAgent;
    const readers = [
        createReader(
            "Microsoft",
            /NT 10.0/.test(userAgent),
            LogoMicrosoft,
            "https://www.microsoft.com/en-za/p/snapplify/9nrcwrhppxrj?activetab=pivot:overviewtab",
        ),
        createReader(
            "Windows",
            /((NT 6.3)|(NT 6.2)|(NT 6.1))(?=.*x64)/.test(userAgent),
            LogoWindows,
            "https://getsnapplify.com/release/Snapplify.msi",
        ),
        createReader(
            "Windows",
            /((NT 6.3)|(NT 6.2)|(NT 6.1))(?=.*x86)/.test(userAgent),
            LogoWindows,
            "https://getsnapplify.com/release/Snapplify-x86.msi",
        ),
        createReader(
            "Apple Silicon",
            /Macintosh/.test(userAgent) && isAppleSilicon(),
            LogoMacM1,
            "https://getsnapplify.com/release/Snapplify-AppleSilicon.pkg",
        ),
        createReader(
            "Mac OS",
            /Macintosh/.test(userAgent) && !isAppleSilicon(),
            LogoMac,
            "https://getsnapplify.com/release/Snapplify.pkg",
        ),
        createReader(
            "iOS",
            /iPhone|iPad/.test(userAgent),
            LogoIOS,
            "https://itunes.apple.com/app/snapplify-viewer/id479343631?ls=1&mt=8&at=10lbTQ",
        ),
        createReader(
            "Android",
            /^(?!.*Huawei|.*HUAWEI|.*CrOS).*Android/.test(userAgent),
            LogoAndroid,
            "https://play.google.com/store/apps/details?id=za.co.snapplify",
        ),
        createReader(
            "Huawei",
            /HUAWEI|Huawei/.test(userAgent),
            LogoHuawei,
            "https://appgallery.huawei.com/app/C102670933",
        )
    ];

    /**
     * This is not an ideal solution
     *
     * remove x86.msi if x64 is primary
     * remove x64.msi if x86 is primary
     * remove x86.msi if neither is primary
     */
    if (readers[1].isPrimary) {
        readers.splice(2, 1);
    }

    if (readers[2].isPrimary) {
        readers.splice(1, 1);
    }

    if (!readers[1].isPrimary && !readers[2].isPrimary) {
        readers.splice(2, 1);
    }

    return readers;
}

export const ReaderActive = () => {
    const [readers, setReaders] = useState([]);

    useEffect(() => {
        setReaders(getReaders());
    }, []);

    return (
        <div>
            {readers.map((i) => {
                return (
                    i.isPrimary ?
                        <div key={i.platform}>
                            <div className="section_heading ">
                                <h1>Hello Human. <br/> I, Snappbot, recommend the Snapplify Reader for <strong>{i.platform}</strong> on your device!</h1>
                            </div>
                            <a href={i.src} target="_blank" rel="noopener noreferrer"> <img className="os-primary" src={i.logo} alt=""/> </a>
                        </div> : null
                )
            })}
        </div>
    )
}

export const ReaderStatic = () => {
    const [readers, setReaders] = useState([]);

    useEffect(() => {
        setReaders(getReaders());
    }, []);

    return (
        <div className="text-center">
            <h3 className="section_text">
                If I got it wrong, perhaps try one of the following devices
            </h3>
            <div>
                {readers.map((i) => {
                    return (
                        !i.isPrimary ?
                            <a key={i.platform} href={i.src} target="_blank" rel="noopener noreferrer"> <img className="os-secondary" src={i.logo} alt=""/></a>
                            : null
                    )
                })}
            </div>
            <h3 className="section_text">
            </h3>
        </div>
    )
}