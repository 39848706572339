import React from 'react';

export const Header = () => {
    return (
        <header>
            <div>
                <nav className="nav navbar-expand-md navbar-light bg-light">
                    <div className="inner_nav">
                        <div className="container hideRobot">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                                    <img src="https://cdn.snapplify.com/1.0.4/img/logo.svg" height="50" alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </header>
    )
};