import React from 'react';

export const SnapplifyLogo = ({onClick}) => {
    return (
        <img
            src="https://cdn.snapplify.com/1.0.4/img/logo.svg"
            alt="Snapplify Logo"
            onClick={onClick}
            className="app-button"
        />
    );
};